<template>
    <div>
        <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
        <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    
        <v-container>
            <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
            <v-row class="align-center d-flex h-0 ">
                <v-col xl="3" lg="4" sm="8" class="">
                    <checkmong-home-bar title="ข้อมูลสุขภาพ"></checkmong-home-bar>
                </v-col>
            </v-row>
            <br />
            <v-row class="align-center d-flex h-0 pb-0" style="justify-content: center;">
                <v-col xl="3" lg="4" sm="8">
                    <v-expansion-panels style="border-radius: 10px;">
                        <v-expansion-panel>
                            <v-expansion-panel-header expand-icon="info_outline">
                                <v-row class="">
                                    <v-col cols="2">
                                        <v-icon color="purple">
                                            person
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="10" style="text-align: left;padding-top: 26px;">
                                        <v-row><span class="pb-1">{{res_info.fullname}}</span></v-row>
                                        <v-row><span style="font-size: 11px;">อัพเดทล่าสุด ณ วันที่ <span class="text-purple">{{new Date(last_update).toLocaleString('th-TH', { dateStyle: 'long',timeStyle:'short'})}}</span></span></v-row>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-divider></v-divider>
                                <br>
                                <v-row>
                                    <v-col  cols="12" style="padding-left: 28px;">
                                        <!-- <v-row><span style="font-size: 11px;">เลขบัตรประจำตัวประชาชน : <span class="text-purple">{{res_info.idcard}}</span></span></v-row> -->
                                        <v-row><span style="font-size: 11px;">วัน/เดือน/ปี เกิด : <span class="text-purple">{{new Date(res_info.birthdate).toLocaleString('th-TH', { dateStyle: 'long'})}}</span></span></v-row>
                                        <v-row><span style="font-size: 11px;">เบอร์โทรศัพท์ : <span class="text-purple">{{res_info.phone_number}}</span></span></v-row>
                                        <v-row><span style="font-size: 11px;">ที่อยู่ : <span class="text-purple">{{res_info.address}}</span></span></v-row>
                                        <br>
                                    </v-col>
                                </v-row>
                               
                            </v-expansion-panel-content>
    
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
            <!-- <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
                <v-col xl="3" lg="4" sm="8" style="text-align: right; margin-bottom: -22px;">
                    <v-btn @click="$router.push('/scan_add_health_record')" tile depressed color="text-purple" style="background-color: #f0f2f5;">
                        <v-icon left style="color: #1F98BD;">
                            post_add
                        </v-icon>
                        เพิ่มข้อมูลสุขภาพ
                    </v-btn>
                </v-col>
            </v-row> -->
    
            <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
                <v-col xl="3" lg="4" sm="8">
                    <v-card style="border-radius: 15px" elevation="1">
                        <v-card-text>
                            <v-row dense style="background-color: #ffffff; border-radius: 10px;">
                                <v-col cols="12">
                                    <v-list-item-title>โรคประจำตัว</v-list-item-title>
                                    <v-list-item-subtitle>
                                        <li v-for="(item) in congenital_ds">{{item}}</li>
    
                                    </v-list-item-subtitle>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item-title>อายุ</v-list-item-title>
                                    <v-list-item-subtitle>{{age}}</v-list-item-subtitle>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item-title>น้ำหนัก(kg)</v-list-item-title>
                                    <v-list-item-subtitle>{{weight}}</v-list-item-subtitle>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item-title>ส่วนสูง(cm)</v-list-item-title>
                                    <v-list-item-subtitle>{{height}}</v-list-item-subtitle>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item-title>BMI (kg/m2)</v-list-item-title>
                                    <v-list-item-subtitle>{{bmi}}</v-list-item-subtitle>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="6">
                                    <v-list-item-title>AZQ Score</v-list-item-title>
                                    <v-list-item-subtitle>{{score}}</v-list-item-subtitle>
                                    <v-divider></v-divider>
                                </v-col>
    
                            </v-row>
    
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
                <v-col xl="3" lg="4" sm="8">
                    <v-card style="border-radius: 15px" elevation="1">
    
                        <!-- <card-chart-revenue></card-chart-revenue> -->
    
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-show="!$vuetify.breakpoint.mobile" class="align-start ml-4"  style="padding-top: 15px">
                <v-btn outlined @click="$router.go(-1)" style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
            </v-row>
    
            <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
                <v-row class="align-start" >
                    <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="ml-4 border-radius-xl">
                        <v-icon small>west</v-icon>
                    </v-btn>
                    
                </v-row>
            </v-footer>
    
        </v-container>
    </div>
    </template>
    
    <script>
    import CheckmongHomeBar from "@/components/CheckmongHomeBar.vue";
    import {
        APIURL
    } from '@/constants'
    import Vue from 'vue'
    import WaitingModal from "@/components/WaitingModal.vue";
    export default {
        name: "calendar-patient",
        components: {
            CheckmongHomeBar,
            WaitingModal
        },
        data: () => ({
            display_date:'',
            res_info: {},
            update_status_wait: false,
            active_waiting: false,
            title: '',
            status: '',
            congenital_ds: ['ไม่มี'],
            age: '-',
            patient_health_records: '-',
            height: '-',
            bmi: '-',
            score: '-',
            systolic: '-',
            diastolic:'-',
            sugar: '-',
            hr: '-',
            regular_hr: '-',
            health_records: {},
            last_update: '-',
            weight: '-',
            user_patient_id: '',
            create_date:'-',

        }),
    
        created() {
            
            // this.getUserInfo()
            
            // //console.log("data is", this.user_patient_id);
            // //console.log("this.$route", this.$route);
            
        },
        mounted(){
    
            this.user_patient_id = this.$store.state.qr_patient_id;
            this.create_date = this.$store.state.qr_patient_info.create_date
            this.getPatientHealthRecords()
            // //console.log('this.res_info')
            // //console.log(this.res_info)
       
        },
        
        watch: {
        //   health_records(val){
        //     if(val.azq_score_records){this.score = val.azq_score_records[0].score}
        //     if(val.congenital_ds){this.congenital_ds = val.congenital_ds}
        //     if(val.patient_health_records.length > 0){
        //       this.last_update = val.patient_health_records[val.patient_health_records.length-1].date
        //       this.diastolic = val.patient_health_records[val.patient_health_records.length-1].diastolic
        //       this.regular_hr = val.patient_health_records[val.patient_health_records.length-1].regular_hr
        //       this.sugar = val.patient_health_records[val.patient_health_records.length-1].sugar
        //       this.systolic = val.patient_health_records[val.patient_health_records.length-1].systolic
        //       this.weight = val.patient_health_records[val.patient_health_records.length-1].weight
        //       this.hr = val.patient_health_records[val.patient_health_records.length-1].hr
        //       const options = { dateStyle: 'long', timeStyle:'short' };
        //       this.display_date = new Date(this.last_update).toLocaleString('th-TH', options);
        //       }
        //     this.age = val.age
        //   }
        },
        methods: {

            async getPatientHealthRecords() {
                //console.log('this.user_patient_id:',this.user_patient_id)

                this.active_waiting = true
                this.title = 'กำลังอัพเดทข้อมูล',
                this.status = 'loading'
                await Vue.axios.post(APIURL + '/get_patient_health_records', {
                    "user_id": this.user_patient_id
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    if (response.data) {
                        this.active_waiting = false
                        // this.$store.commit('setUserInfo',response.data);
                        this.health_records = response.data
                        this.res_info = this.health_records
                        // this.$store.commit('setQrPatientId',"0")
                        //console.log('---------cc-----')
                        //console.log(this.res_info)
                        if(this.res_info.height) {this.height = this.res_info.height}
                        if(this.res_info.weight) {this.weight = this.res_info.weight}
                        if(this.res_info.height && this.res_info.weight) {this.bmi = (this.weight/((this.height/100)**2)).toFixed(2)}
                        this.updateData(this.health_records)
                        // this.district = response.data.district
                        // this.subdistrict_list = response.data.location_list
                        // this.location_id = response.data.location_list
                    } else {
                        this.active_waiting = false
    
                    }
    
                })
    
            },
            updateData(val){
                if(val.azq_score_records){this.score = val.azq_score_records[val.azq_score_records.length-1].score}
                if(val.azq_score_records){this.last_update = val.azq_score_records[val.azq_score_records.length-1].create_date}
                if(this.last_update == '-'){
                    this.last_update = this.create_date
                }



                if(val.congenital_ds){this.congenital_ds = val.congenital_ds}
                if(val.patient_health_records.length > 0){
                this.diastolic = val.patient_health_records[val.patient_health_records.length-1].diastolic
                this.regular_hr = val.patient_health_records[val.patient_health_records.length-1].regular_hr
                this.sugar = val.patient_health_records[val.patient_health_records.length-1].sugar
                this.systolic = val.patient_health_records[val.patient_health_records.length-1].systolic
                this.weight = val.patient_health_records[val.patient_health_records.length-1].weight
                this.hr = val.patient_health_records[val.patient_health_records.length-1].hr
                // const options = { dateStyle: 'long', timeStyle:'short' };
                // this.display_date = new Date(this.last_update).toLocaleString('th-TH', options);
                }
                // //console.log(this.last_update)

                // //console.log('---',this.display_date)
                this.age = val.age
            },

            save(date) {
                this.$refs.menu.save(date);
            },
            validate() {
                this.$refs.form.validate();
            },
            reset() {
                this.$refs.form.reset();
            },
            resetValidation() {
                this.$refs.form.resetValidation();
            },
        },
    };
    </script>
    
    <style scoped>
    .v-card {
        display: flex !important;
        flex-direction: column;
    }
    
    .v-card__text {
        flex-grow: 1;
        overflow: auto;
    }
    
    .action-nav {
        background: #ffffff !important;
        box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
        border-top-left-radius: 18px !important;
        border-top-right-radius: 18px !important;
        height: 77 px !important;
    }
    
    .v-menu__content .v-list-item__title {
        font-size: 0.875rem;
        color: #ffffff;
        color: gray;
    }
    
    .custom-field {
        border-radius: 6px;
    }
    
    .v-picker__title {
        color: #ffffff;
        border-top-left-radius: 0.375rem;
        border-top-right-radius: 0.375rem;
        padding: 16px;
        background-color: #a84192;
    }
    
    .v-main__wrap {
        padding-bottom: 90px;
    }
    
    .position-relative {
        position: relative;
    }
    
    .v-date-picker-table .v-btn.v-btn--active {
        color: #ffffff;
        background-color: #a84192;
    }
    
    .theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
        color: #a84192 !important;
    }
    
    .theme--light.v-btn.v-btn--icon {
        color: #a84192 !important;
    }
    
    .v-list-item__title{
        font-size: 0.7rem;
        color: darkgray;
    }
    .v-dialog>.v-card>.v-card__text {
        padding: 0 24px 0px;
    }
    </style>
    